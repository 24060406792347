import { useState } from "react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { useGeolocated } from "react-geolocated";

export const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { coords, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  });

  const getGeolocation = () => {
    setIsLoading(true);
    getPosition();
    setIsLoading(false);
  };
  return (
    <div className="rounded bg-stone-100 p-5 shadow max-w-lg mx-auto font-mono m-5">
      <div>
        <h1 className="text-2xl font-bold text-stone-900">gps-visualize-app</h1>
      </div>
      {coords ? (
        <iframe
          title="google-map"
          className="mt-3 aspect-video w-full rounded-sm border border-white grayscale"
          src={`https://maps.google.co.jp/maps?ll=${coords?.latitude},${coords?.longitude};&q=${coords?.latitude},${coords?.longitude}&output=embed&t=m&z=18&iwloc=B`}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      ) : (
        <div className="mt-3 aspect-video w-full rounded-sm border border-white">
          <img
            className="w-full animate-pulse blur-sm"
            src="/map-skelton.webp"
            alt="map-skelton"
          />
        </div>
      )}

      <table className="mt-3 w-full table-auto border-collapse overflow-hidden rounded border-2 border-slate-200">
        <thead className="bg-gray-300 text-xs uppercase text-gray-900">
          <tr>
            <th className="w-20 py-3 px-6">項目</th>
            <th className="py-3 px-6">値</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr className="odd:bg-white even:bg-slate-50">
            <td className="py-4 px-6">緯度</td>
            <td className="truncate py-4 px-6">{coords?.latitude}</td>
          </tr>
          <tr className="odd:bg-white even:bg-slate-50">
            <td className="py-4 px-6">経度</td>
            <td className="truncate py-4 px-6">{coords?.longitude}</td>
          </tr>
          <tr className="odd:bg-white even:bg-slate-50">
            <td className="py-4 px-6">精度誤差</td>
            <td className="truncate py-4 px-6">{coords?.accuracy}</td>
          </tr>
          <tr className="odd:bg-white even:bg-slate-50">
            <td className="py-4 px-6">高度</td>
            <td className="truncate py-4 px-6">{coords?.altitude}</td>
          </tr>
        </tbody>
      </table>
      <div className="mt-3 w-full gap-3">
        {isLoading ? (
          <button
            disabled
            v-else
            className="grid w-full place-items-center rounded-md border border-stone-300 bg-stone-600 py-2 text-white shadow-sm disabled:animate-pulse disabled:opacity-30 disabled:hover:opacity-30"
          >
            <div className="animate-spin h-6 w-6 border-4 border-stone-500 rounded-full border-t-transparent"></div>
          </button>
        ) : (
          <button
            onClick={() => getGeolocation()}
            className="flex w-full items-center justify-center rounded-md border border-stone-300 bg-stone-600 py-2 text-white shadow-sm transition-opacity duration-200 hover:opacity-75"
          >
            <MapPinIcon className="mr-1 h-4"></MapPinIcon>
            位置情報取得
          </button>
        )}
      </div>
      <div className="mt-1">
        <p className="text-xs text-stone-500 text-right">
          ©
          <a
            className="underline"
            href="https://www.andeco.co.jp/"
            rel="noopener noreferrer"
          >
            Andeco.inc
          </a>{" "}
          2023
        </p>
      </div>
    </div>
  );
};
